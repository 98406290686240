import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import swal from "sweetalert";
import { API_URL } from "../constant";
import Loader from "./loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $, { readyException } from 'jquery';
import { toExcel } from 'to-excel';
import TestModal from "./test_beneficiary_model";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AllPerformanceList() {

    const navigat = useNavigate();

    const [userData, setUserData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pages, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState();
   // const [typ, setTyp] = useState({ ftype: "all", id: "" });

    const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);

const [selectedDistrict, setSelectedDistrict] = useState('');
const [selectedBlock, setSelectedBlock] = useState('');
 

 const [typ, setTyp] = useState(() => {
        const userLoginType = reactLocalStorage.get("userLoginType");
        if (userLoginType === "Block") {
            return { ftype: "block_id", id: reactLocalStorage.get("block") };
        } else if (userLoginType === "District") {
            return { ftype: "district_id", id: reactLocalStorage.get("district") };
        }
        return { ftype: "all", id: "" }; // Default value
    });


    useEffect(() => {
        document.title = "Performance List";
        getTotalPerfomanceData();
        getDistrictList();
        exportPerfomanceData();
    }, []); // Runs only once on mount

    useEffect(() => {
        const userLoginType = reactLocalStorage.get("userLoginType");
        if ((userLoginType === "Block" || userLoginType === "District") && typ.id) {
            getAllPerformanceData(pages);
            getBlockForBlockLogin();
        } else {
            getAllPerformanceData(pages);
        }
    }, [pages]); // Runs only when `pages` changes

   /*     
    useEffect(() => {
       if(reactLocalStorage.get("userLoginType") == 'Block'){
             setTyp((prev) => ({ ...prev, ftype: "block_id", id: reactLocalStorage.get("block") }));
        }
       else if(reactLocalStorage.get("userLoginType") == 'District'){  console.log("test");
              setTyp((prev) => ({ ...prev, ftype: "district_id", id: reactLocalStorage.get("district") }));
        }
        document.title = "Performance List";
        getTotalPerfomanceData()
        getDistrictList();
        exportPerfomanceData();
    }, [1]
    );

    useEffect(() => {
       
        getAllPerformanceData(pages);

    }, [pages]);

    */

       /* useEffect(() => {
            const userLoginType = reactLocalStorage.get("userLoginType");

            if (userLoginType === "Block") {
                setTyp((prev) => ({ ...prev, ftype: "block_id", id: reactLocalStorage.get("block") }));
               
            } else if (userLoginType === "District") {
                console.log("test");
                setTyp((prev) => ({ ...prev, ftype: "district_id", id: reactLocalStorage.get("district") }));
            }else{
                 getTotalPerfomanceData();
                getDistrictList();
                exportPerfomanceData();
            }

            document.title = "Performance List";
        }, [1]); //  Runs only once on mount

        //  Run API calls AFTER `typ` is updated
        useEffect(() => {
            if (typ.id) {  // Only call API if `typ.id` is set
                getTotalPerfomanceData();
                getDistrictList();
                exportPerfomanceData();
            }
        }, [typ]); // Runs whenever `typ` updates

        useEffect(() => {
             const userLoginType = reactLocalStorage.get("userLoginType");
             if (userLoginType === "Block" || userLoginType === "District") { 
                   if(typ.id != 0 || typ.id !=""){
                   getAllPerformanceData(pages);
                    getBlockForBlockLogin();
                     }
                 }else{
                     getAllPerformanceData(pages);
                 }
            }, [typ, pages]);  */

    /**************************Get Distirct Data*********************** */

    let getDistrictList = async (e) => {

        let state_id = 1;
        const regDataDistrict = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'state_id': state_id })
        }
        const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
        const getdataDistrict = await getDistrict.json();
        // console.log(getdataDistrict.Rdata);
        if (getdataDistrict.Rdata.length > 0) {
            setDistrictData(getdataDistrict.Rdata);

        }

    }

    /**************************Get Blocks*********************** */

    let getBlock = async (e) => {
        setBlockData([]);
        let district_id = e.target.value;
        //   console.log(district_id);
        const reDataDistrict = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'district_id': district_id })
        }
        const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
        const getBlockData = await getBlock.json();
        //console.log(getBlockData);
        if (getBlockData.Rdata.length > 0) {
            setBlockData(getBlockData.Rdata);

        }

    }


    /************Get all patient user********************** */
    let getAllPerformanceData = async (page) => {
       //setIsLoading(true);
        //setUserData([]); 
        let datas = { ftype: typ.ftype, data: typ.id, page: page, per_page: perPage ,fromDate: startDate1, toDate: endDate1};


        var url = "";

        url = 'admin/test_performance_admin';


        const res = await axios.post(API_URL + url, datas)
            .then(ress => {

                let filteredData = ress.data.Rdata.filter(item => item.user_id !== null);

                setUserData(filteredData);
                setIsLoading(false);
            });
        //  console.log(res.data);
    }

    /************Get total Perfomance********************** */
    let getTotalPerfomanceData = async () => {


        let datas = { ftype: typ.ftype, data: typ.id , fromDate: startDate1, toDate: endDate1};
        //	console.log(datas);
        var url = "";

        url = 'admin/total_test_performance_admin';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {
                // console.log(ress.data.Rdata.length);
                setTotalRows(ress.data.Rdata.length);
                //setTotalData(ress.data.Rdata);

            });


    }
    /***************************Export Performance*****************************8 */
    let exportPerfomanceData = async () => {


        let datas = { ftype: typ.ftype, data: typ.id , fromDate: startDate1, toDate: endDate1};
        //	console.log(datas);
        var url = "";

        url = 'admin/export_test_performance_admin';

        const res = await axios.post(API_URL + url, datas)
            .then(ress => {


                setTotalData(ress.data.Rdata);

            });


    }

    const fetchUsers = async page => {
        setLoading(true);
        getAllPerformanceData(page);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        getAllPerformanceData(page)
        setPerPage(newPerPage);
        setLoading(false);
    };

    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;

  }

  const handleStartDateChange = (date) => {
   
     if (date === null) {
        setStartDate(null);
        setStartDate1(null);
      } else {
        let formattedDate = formatDate(date);
        setStartDate(date);
        setStartDate1(formattedDate);
      }
  };


  const handleEndDateChange = (date) => {
    
      if (date === null) {
        setEndDate(null);
        setEndDate1(null);
      } else {
        let formattedDate = formatDate(date);
        setEndDate(date);
        setEndDate1(formattedDate);
      }

  };




    var headers = [

        { label: 'District', field: 'district_name' },
        { label: 'Block', field: 'block_name' },
        { label: 'CHC/PHC', field: 'chc_name' },
        { label: 'ANM Name', field: 'anm_name' },
        { label: 'Mobile No.', field: 'mobile_no' },
        { label: 'Performed Test', field: 'cnt' },



    ]


    const dwn = () => {

        var content = toExcel.exportXLS(headers, totalData, 'AllTestPerformedData');
        // in node you must open or save the content
        //require('fs').writeFileSync('filename.xls', content);


    }



    const columns = [
        {
            name: 'District Name',
            selector: row => row.district_name,
            sortable: true,

        },
        {
            name: 'Block Name',
            selector: row => row.block_name,
            sortable: true,

        },
        {
            name: 'CHC Name',
            selector: row => row.chc_name,
            sortable: true,

        },

        {
            name: 'ANM Name',
            selector: row => row.anm_name,
            sortable: true,
            // width: '110px',

        },
        {
            name: 'Mobile No.',
            selector: row => row.mobile_no,
            sortable: true,
            // width: '110px',

        },

        {
            name: 'Performed Test',
            selector: row => row.cnt,


        },




    ];




    const getFilterUserData = () => {
      
        if (startDate1 == null && endDate1 == null) {
          console.log("in"+startDate1,endDate1);
         
        } else {
          if (startDate1 > endDate1) {
           // setIsLoading(false);
            swal("From Date must be smaller than To Date");
            return false;
          }
          if(startDate1 == null || endDate1 == null){
             swal("Must Select From Date and To Date");
            return false;
          }
       }
        // setIsLoading(true);
        getTotalPerfomanceData();
        getAllPerformanceData(pages);
        setPerPage(10);
        exportPerfomanceData();

    }

const setSelectData = (ftypeData,idData)=>{
      console.log("test3")
      if(ftypeData=="block_id"){
          if(idData==0 && ftypeData=="block_id"){

          if(selectedDistrict =="" ){  
             setTyp({ ftype: 'all', id: "" });
          }else{
            setTyp({ ftype: 'district_id', id: selectedDistrict });
          }
       }else{
        setTyp({ ftype: ftypeData, id: idData });
       }
     }

      if(ftypeData=="district_id"){
          if(idData==0 && ftypeData=="district_id"){
             setTyp({ ftype: 'all', id: "" });
          }else{
        setTyp({ ftype: ftypeData, id: idData });
       }
     }
  }


const clearData = () =>{

// setTyp({ ftype: "all", id: "" });
setStartDate(null);
setEndDate(null);
setStartDate1(null);
setEndDate1(null);
// setSelectedDistrict('');
// setSelectedBlock('');

}


useEffect(() => {
    // Fetch stored district and block IDs from local storage
    const storedDistrictId = reactLocalStorage.get("district"); 
    const storedBlockId = reactLocalStorage.get("block"); 
      console.log(districtData, blockData,storedBlockId,storedDistrictId);
    // Set state if values exist
    if (storedDistrictId) setSelectedDistrict(storedDistrictId);
    if (storedBlockId) setSelectedBlock(storedBlockId);
}, [districtData, blockData]); // Run effect when data is available




const getBlockForBlockLogin = async (e) => {
    console.log("data-----");
        setBlockData([]);
        let district_id = reactLocalStorage.get("district");
        //   console.log(district_id);
        const reDataDistrict = {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'district_id': district_id })
        }
        const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
        const getBlockData = await getBlock.json();
        //console.log(getBlockData);
        if (getBlockData.Rdata.length > 0) {
            setBlockData(getBlockData.Rdata);

        }

    }


    return (

        <div className="container-scroller">

            <Hor_header />

            <div className="container-fluid page-body-wrapper">

                <Ver_header />

                <div className="main-panel">
                    {isLoading ? (<Loader />) : (
                        <>
                            <div className="content-wrapper">



                                <div className="row">

                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select District </label>
                                            <div className="col-sm-12">
                                            
                                                 <select className="form-control" name="district" 
                                                         onChange={(e) => {setSelectedDistrict(e.target.value);  getBlock(e); setSelectData('district_id',e.target.value);; }} 
                                                         value={selectedDistrict} required disabled ={((reactLocalStorage.get("userLoginType") == "Block")|| (reactLocalStorage.get("userLoginType") == "District")) ? 'disabled':'' } >
                                                    <option value='0' >Select</option>
                                                    {districtData.map(district => (
                                                        ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label className="col-sm-12">Select Block </label>
                                            <div className="col-sm-12">
                                              
                                                <select className="form-control" name="block"
                                                 onChange={(e) => {  setSelectedBlock(e.target.value); setSelectData('block_id',e.target.value); }}
                                                  value={selectedBlock}  required disabled ={((reactLocalStorage.get("userLoginType") == "Block")) ? 'disabled':'' } > 
                                                    <option value='0' >Select</option>
                                                    {blockData.map(blocks => (
                                                        ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}>{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select Start Date </label>
                                      <div className="col-sm-12">
                                        <DatePicker
                                          selected={startDate}
                                          onChange={handleStartDateChange}
                                          name="dob"
                                          dateFormat="dd-MM-yyyy"
                                          dropdownMode="select"
                                          showMonthDropdown
                                          showYearDropdown
                                          adjustDateOnChange
                                          minDate={new Date('01-01-1950')}
                                          maxDate={new Date()}
                                          placeholderText="From"
                                          className="form-control"
                                          id="dobpicker"
                                          tabIndex="5"
                                           onKeyDown={(e) => {
                                                        if (e.key === "Backspace") {
                                                          setStartDate(null);
                                                          setStartDate1(null);
                                                        }else{
                                                           e.preventDefault() 
                                                        }
                                                    }}
                                        //  onKeyDown={e => e.preventDefault()}
                                          autoComplete="off"

                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-2">
                                    <div className="form-group row">
                                      <label className="col-sm-12">Select End Date </label>
                                      <div className="col-sm-12">
                                        <DatePicker
                                          selected={endDate}
                                          onChange={handleEndDateChange}
                                          name="dob"
                                          dateFormat="dd-MM-yyyy"
                                          dropdownMode="select"
                                          showMonthDropdown
                                          showYearDropdown
                                          adjustDateOnChange
                                          minDate={new Date('01-01-1950')}
                                          maxDate={new Date()}
                                          placeholderText="To"
                                          className="form-control"
                                          id="dobpicker"
                                          tabIndex="5"
                                          onKeyDown={(e) => {
                                                        if (e.key === "Backspace") {
                                                          setEndDate(null);
                                                          setEndDate1(null);
                                                        }else{
                                                           e.preventDefault() 
                                                        }
                                                    }}
                                            //onKeyDown={e => e.preventDefault()}
                                          autoComplete="off"

                                        />
                                      </div>
                                   
                                    </div>
                                  </div>
                                 


                                    <div className="col-md-2">

                                        <div className="form-group row">

                                             <div className="col-sm-12 export-fit">
                                           
                                                <button className="btn btn-primary" onClick={getFilterUserData}>Go</button>&nbsp;
                                                <button className="btn btn-success" onClick={dwn} >Export</button> 
                                                { /*<button  onClick={() => { clearData(); }} className="btn smbtn">Clear date</button> */}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="card-title">Performed Test List</h4>
                                                <div className="row">

                                                </div>



                                                <div className="table-responsive">



                                                    <DataTable

                                                        columns={columns}
                                                        data={userData}
                                                        progressPending={loading}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}

                                                    />



                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <Footer />
                        </>
                    )}
                </div>

            </div>

        </div>);
}

export default AllPerformanceList;
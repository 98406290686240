import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import $, { readyException } from 'jquery';

function Ver_header() {
  const [hideInv, setHideInv] = useState(false);
  const [hide, setHide] = useState(false);
  const [hideBlk, setHideBlk] = useState(true);

  useEffect(() => {
    if (reactLocalStorage.get('userLoginType') == "Admin") {
      setHide(true);
    }
    if (reactLocalStorage.get('userLoginType') == "Block") {
      setHideBlk(false);
    }

  }, [1]);

  let InventorySubShow = (e) => {
    e.preventDefault();
    setHideInv(true);
  }



  //alert(hideInv);
  //console.log(hide);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
       {(reactLocalStorage.get("userLoginType") != "DistrictStore")?<>{(reactLocalStorage.get("userLoginType") != "Store") ? <><li className="nav-item">
          <NavLink className="nav-link" to="/dashboard">
            <i className="typcn typcn-device-desktop menu-icon"></i>
            <span className="menu-title">Dashboard </span>
          </NavLink>
        </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#anm" aria-expanded="false" aria-controls="anm">
              <i className="typcn typcn-compass menu-icon"></i>
              <span className="menu-title">User Management </span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="anm">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/add_user">Add ANM/RBSK</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="anm">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/all_users">View ANM/RBSK</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="anm">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/all_hb_meter_req">HB Meter Requests</NavLink></li>
              </ul>
            </div>
            {(reactLocalStorage.get("userLoginType") !== "Chc")?<div className="collapse" id="anm">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/test-performance">Test Performance </NavLink></li>
              </ul>
            </div>:""}
          </li>
          <li className="nav-item" style={{ "display": (hide) ? "" : "none" }}>
            <a className="nav-link" data-toggle="collapse" href="#chc" aria-expanded="false" aria-controls="chc">
              <i className=" typcn typcn-weather-snow menu-icon"></i>
              <span className="menu-title">Hospital Management</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="chc">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/addChc">Add DH/SDH/CHC</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="chc">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/allChc">View DH/SDH/CHC</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="chc">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/add-store">Add Storekeepers</NavLink></li>
                </ul>
              </div>
              <div className="collapse" id="chc">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/all-store-list">View Storekeepers</NavLink></li>
                </ul>
              </div>
            {/* <div className="collapse" id="chc">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/hbmeter_chc_req">CHC Item Requests</NavLink></li>
      </ul>
    </div> */}

          </li>
          {/* {(reactLocalStorage.get("userLoginType") == "Admin") ? <>
            <li className="nav-item" >
              <a className="nav-link" data-toggle="collapse" href="#store" aria-expanded="false" aria-controls="store">
                <i className=" typcn typcn-cloud-storage-outline menu-icon"></i>
                <span className="menu-title">Store Manager </span>
                <i className="menu-arrow"></i>
              </a>
              
            </li></> : ""} */}
          {(reactLocalStorage.get("userLoginType") == "District") || (reactLocalStorage.get("userLoginType") == "Admin") ? <>
            <li className="nav-item" >
              <a className="nav-link" data-toggle="collapse" href="#block" aria-expanded="false" aria-controls="block">
                <i className=" typcn typcn-world menu-icon"></i>
                <span className="menu-title">Block Management </span>
                <i className="menu-arrow"></i>
              </a>
              <div className="collapse" id="block">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/addBlock">Add Block</NavLink></li>
                </ul>
              </div>
              <div className="collapse" id="block">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/allBlockUser">View Block</NavLink></li>
                </ul>
              </div>
            </li></> : ""}

          <li className="nav-item" style={{ "display": (hide) ? "" : "none" }}>
            <a className="nav-link" data-toggle="collapse" href="#District" aria-expanded="false" aria-controls="District">
              <i className=" typcn typcn-social-dribbble menu-icon"></i>
              <span className="menu-title">District Management </span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="District">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/addDistrict">Add District</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="District">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/allDistrictUser">View District</NavLink></li>
              </ul>
            </div>

            <div className="collapse" id="District">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/add-district-store">Add District Store</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="District">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/district-store-list">View District Store</NavLink></li>
              </ul>
            </div>

          </li>

          <li className="nav-item" style={{ "display": (hide) ? "" : "none" }}>
            <a className="nav-link" data-toggle="collapse" href="#trans" aria-expanded="false" aria-controls="trans">
              <i className=" typcn typcn-export menu-icon"></i>
              <span className="menu-title">Transfer Management </span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="trans">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/tranfer_beneficiary">Transfer Beneficiary</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="trans">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/view_ben_archive">Transfer Beneficiary Archive</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="trans">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/transfer_anm_medical">Transfer Anm/Medical Team </NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="trans">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/view_anm_archive">Transfer Anm/Medical Archive</NavLink></li>
              </ul>
            </div>
          </li>


          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#patient" aria-expanded="false" aria-controls="patient">
              <i className="typcn typcn-radar menu-icon"></i>
              <span className="menu-title">Beneficiary Management</span>
              <i className="menu-arrow"></i>
            </a>
            {(reactLocalStorage.get("userLoginType") == "Admin") ? <> <div className="collapse" id="patient">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/add-beneficiary">Add Beneficiary</NavLink></li>
              </ul>
            </div>
              <div className="collapse" id="patient">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/beneficiary-list">Beneficiary List</NavLink></li>
                </ul>
              </div>
              <div className="collapse" id="patient">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <NavLink className="nav-link" to="/recovered-list">Recovered List</NavLink></li>
                </ul>
              </div>

            </> : ""}
            <div className="collapse" id="patient">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/all_patient">View Users</NavLink></li>
              </ul>
            </div>
            {/* <div className="collapse" id="patient" style={{"display":(hide)?"":"none"}}> */}

            <div className="collapse" id="patient" >
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/allPatient_csv">Reports </NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="patient">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/follow-up-list">Follow-up Reports</NavLink></li>
              </ul>
            </div>
            <div className="collapse" id="patient">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/reffered_cases"> Referred Cases </NavLink></li>
              </ul>
            </div>
          </li></> : ""}</>:""}

        {/*<li className="nav-item" style={{"display":(hide)?"":"none"}} >
    <a className="nav-link" data-toggle="collapse" href="#inv" aria-expanded="false" aria-controls="inv">
      <i className="typcn typcn-social-instagram-circular menu-icon"></i>
      <span className="menu-title">Inventory Item</span>
      <i className="menu-arrow"></i>
    </a>
    <div className="collapse" id="inv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_item">Add Item</NavLink></li>
      </ul>
    </div>
    <div className="collapse" id="inv">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/view_item_list">View Item List</NavLink></li>
      </ul>
    </div>
    
  </li>*/}

        {/*<li className="nav-item" >
    <a className="nav-link" data-toggle="collapse" href="#mnginv" aria-expanded="false" aria-controls="mnginv">
      <i className="typcn typcn-eject menu-icon"></i>
      <span className="menu-title">Manage Inventory </span>
      <i className="menu-arrow"></i>
    </a>
    {(reactLocalStorage.get("userLoginType")=="Admin")?
    <div className="collapse" id="mnginv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_inv_item">Add Inventory Item</NavLink></li>
      </ul>
    </div>:""}
  
  
    <div className="collapse" id="mnginv">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/view_inventory">View Inventory item</NavLink></li>
      </ul>
    </div>
    {(reactLocalStorage.get("userLoginType")=="Chc")?<div className="collapse" id="mnginv">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/add_req_inventory">Item Request</NavLink></li>
      </ul>
    </div>:""}
    {(reactLocalStorage.get("userLoginType")=="Chc")?<div className="collapse" id="mnginv">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/my_inventory_req">My Items Request</NavLink></li>
      </ul>
    </div>:""}
    <div className="collapse" id="mnginv">
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/inventory_req">Inventory Requests</NavLink></li>
      </ul>
    </div>

    <div className="collapse" id="mnginv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/inventory_item_assign_wr">Assign Item Without Req</NavLink></li>
      </ul>
    </div>

    {(reactLocalStorage.get("userLoginType")=="Admin")?
    <div className="collapse" id="mnginv" >
      <ul className="nav flex-column sub-menu">
        <li className="nav-item"> <NavLink className="nav-link" to="/inv_item_state_his">Added Item History</NavLink></li>
      </ul>
    </div>:""}
    
  </li>*/}
        <li className="nav-item" style={{ "display": (hideBlk) ? "" : "none" }}>
          <Link className="nav-link" data-toggle="collapse" to="#" onClick={(e) => setHideInv(!hideInv)} aria-expanded="false" aria-controls="mnginv">
            <i className="typcn typcn-eject menu-icon"></i>
            <span className="menu-title">Manage Inventory</span>
            <i className="menu-arrow"></i>
          </Link>
          <div className="collapse" id="mnginv" style={{ "display": (hideInv) ? "block" : "none" }} >
            <ul className="nav flex-column sub-menu">
              {
                (reactLocalStorage.get("userLoginType") == "Admin") ?
                  <>
                    <li className="nav-item"> <NavLink className="nav-link" to="/add_inv_item">Add In Stock</NavLink></li>
                    <li className="nav-item"> <NavLink className="nav-link" to="/view_inventory">View In Stock</NavLink></li>
                    <li className="nav-item"> <NavLink className="nav-link" to="/district-request">District Request</NavLink></li>
                  </>
                  : <>
                    <li className="nav-item"> <NavLink className="nav-link" to="/view_inventory">View In Stock</NavLink></li>
                    <li className="nav-item"> <NavLink className="nav-link" to="/add_req_inventory">Send Request</NavLink></li>
                    <li className="nav-item"> <NavLink className="nav-link" to="/my_inventory_req">My Request</NavLink></li></>
              }
              {
                (reactLocalStorage.get("userLoginType") == "District") ?
                  <li className="nav-item"> <NavLink className="nav-link" to="/my_chc_req">DH/SDH/CHC Request</NavLink></li>
                  : ""
              }
              {
                (reactLocalStorage.get("userLoginType") == "Chc" || reactLocalStorage.get("userLoginType") == "Store") ?
                  <li className="nav-item"> <NavLink className="nav-link" to="/my_anm_req">ANM Request</NavLink></li>
                  : ""
              }
              {/* <li className="nav-item"> <NavLink className="nav-link" to="/add_inv_item">Issue Items</NavLink></li> */}


              {(reactLocalStorage.get("userLoginType") == "Admin") ?
                <>
                  <li className="nav-item"> <NavLink className="nav-link" to="/assign_item_wr">Issue Items</NavLink></li>
                  <li className="nav-item"> <NavLink className="nav-link" to="/allIssuedItemList">Issue Items History</NavLink></li>
                  <li className="nav-item"> <NavLink className="nav-link" to="/inv_item_state_his">Added Item History</NavLink></li>
                </> : ""}

              {(reactLocalStorage.get("userLoginType") == "District") ?
                <>
                  <li className="nav-item"> <NavLink className="nav-link" to="/assign_item_dist_wr">Issue Items</NavLink></li>
                  <li className="nav-item"> <NavLink className="nav-link" to="/allIssuedItemList">Issue Items History</NavLink></li>
                </> : ""}

              {(reactLocalStorage.get("userLoginType") == "Chc" || reactLocalStorage.get("userLoginType") == "Store") ?
                <>
                  <li className="nav-item"> <NavLink className="nav-link" to="/assign_item_chc_wr">Issue Items</NavLink></li>
                  <li className="nav-item"> <NavLink className="nav-link" to="/allIssuedItemList">Issue Items History</NavLink></li>
                </> : ""}


            </ul>
          </div>
        </li>
        {(reactLocalStorage.get("userLoginType") != "DistrictStore")?<>{(reactLocalStorage.get("userLoginType") != "Store") ? <><li className="nav-item" >
          <a className="nav-link" data-toggle="collapse" href="#notif" aria-expanded="false" aria-controls="notif">
            <i className=" typcn typcn-world menu-icon"></i>
            <span className="menu-title">Notification Management </span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="notif">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/notification">Send Notification</NavLink></li>
            </ul>
          </div>

        </li>
          {(reactLocalStorage.get("userLoginType") != "Admin") ? <> <li className="nav-item" >
            <a className="nav-link" data-toggle="collapse" href="#mpr" aria-expanded="false" aria-controls="mpr">
              <i className=" typcn typcn-world menu-icon"></i>
              <span className="menu-title">MPR Management </span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="mpr">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <NavLink className="nav-link" to="/mpr">MPR</NavLink></li>
              </ul>
            </div>

          </li> </> : ""}
        </> : ""}</>:""}



        <li className="nav-item" style={{ "display": (hide) ? "" : "none" }}>
          <a className="nav-link" data-toggle="collapse" href="#master" aria-expanded="false" aria-controls="master">
            <i className="typcn typcn-briefcase menu-icon"></i>
            <span className="menu-title">Master Data </span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_states">States</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_districts">Districts</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_blocks">Blocks</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_chc">CHCs</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_sub_center">Sub Center</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_village">Villages</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_school">Schools</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/setting">Settings</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/add_item">Add Stock Item</NavLink></li>
            </ul>
          </div>
          <div className="collapse" id="master">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <NavLink className="nav-link" to="/view_item_list">View Stock Item</NavLink></li>
            </ul>
          </div>
        </li>

      </ul>

    </nav>
  );
}
export default Ver_header;   
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import Hor_header from '../../components/header/hor_header';
import Ver_Header from '../../components/header/ver_header';
import Footer from '../../components/footer/footer';
import { API_URL } from "../constant";
import axios from "axios";
//import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-responsive";

import $, { readyException } from 'jquery';
import ReactTooltip from "react-tooltip";

import Chart from 'react-apexcharts';
import ApexData from './donutApex';
import LineApexes from "./newLineApex";
//import BarApexes from './newBarChart';
import PieApex from './pieApex';
import StackedBarApexes from './stackBarChart';
import MapCharts from "./MapCharts";
import AmDonuts from './amDonut';
import AmPie from './ampie';
import AmPies from './ampieCast';
import AmPieRef from './amPieReferred';
import AmPiesFollowup from './amPieFollowup';
import Loader from "./loading";


//import { reactLocalStorage } from 'reactjs-localstorage';
//reactLocalStorage.clear();
function Dashboard() {
  const navigat = useNavigate();

  /************************************* */
  const [userData, setUserData] = useState([]);
  //  const [userDataTT,setUserDataTT] =useState([]);
  const [userBtype, setUserBtype] = useState([]);
  const [btype, setBtype] = useState('all');
  /*****************Date****************************** */
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  /************************************************ */
  const [hideDiv, setHideDiv] = useState(false);
  /************************************************************ */
  const [chcPhc, setChcPhc] = useState([]);
  /******************************************************** */
  const [block, setBlock] = useState([]);
  /******************************************************** */
  const [content, setContent] = useState("");
  /***************************************************** */
  const [pdata, setPdata] = useState('');
  /***************************************************** */
  const [btypes, setBtypes] = useState('all');
  /***************************************************** */

  const [tpf, setTpf] = useState('all');

  const [tperf, setTperf] = useState('all');

  const [isLoading, setIsLoading] = useState(true);
  /******************************************************** */
  const [districtData, setDistrictData] = useState([]);
  const [vanue, setVanue] = useState("");

  //const [selectid,setSelectid] = useState();
  /******************************************************* */

  $('#example').DataTable().destroy();

  $(document).ready(function () {

    $("#tab1 rect,#tab1,.rsm-geography").bind("mousewheel", function () {
      return false;
    });

    $(".rsm-geographies,g,rect,g.rsm-zoomable-group").on('contextmenu', function (e) {
      e.preventDefault();
    });
    // $("react g").draggable("disable");


    //    $('.rsm-geographies,g,rect,g.rsm-zoomable-group').off('click');


    $('#example').DataTable({
      destroy: true,
      bFilter: false,
      bInfo: false,
      "iDisplayLength": 5,
      "aLengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]]
      //    dom: 'Bfrtip',

    });


    // $( "#tabs-194795" ).on('click',function() {
    //   $("#tabs-194795").css("pointer-events","none");
    // });





  });



  /****************************************************************** */
  useEffect(() => {
    document.title = 'Dashboard';
    getAllPatientData('all', '');
    getAllBeneficiaryType();
    getChcPhc();
    getDataBlock();
   // topPerformer(tpf, btypes, vanue);
   // getTotalTestCountByBlock(tperf, btypes, vanue);
    getDistrictName();
    setPdata('all');
    getDistrictList();
    // getTotalNoOfBeneficiary();
    // getTotalNoOfHbCasesMonthly();
  }, [1]
  );





  /***********************Apex Chart******************************************* */

  //const[options,setOption]=useState({labels:["a","b","c","d","e"]});
  //const[series,setSeries]=useState([44, 55, 41, 17, 15]);

  /*** AM Dount Chart */
  const [amdatas, setAmdatas] = useState([]);


  /*** AM Cast Chart */
  const [amCast, setAmCast] = useState([]);

  /*** AM Referred Chart */
  const [amRef, setAmRef] = useState([]);

  /*** AM followup Chart */
  const [amFollowup, setAmFollowup] = useState([]);

  /******AM Pie Chart************* */

  const [dataPie, setDataPie] = useState([]);

  /** Donut */
  const [options, setOption] = useState({});
  const [series, setSeries] = useState([]);

  /** pie chart */

  const [pieOptions, setPieOption] = useState({});
  const [pieSeries, setPieSeries] = useState([]);

  /***Line chart */
  const [optionsLine, setOptionLine] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 0.3,
      discrete: [],
      shape: "circle",
      radius: 3,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },
    fill: { colors: ['#dc3545', '#f2c010', '#fdff00', '#3f8755'] },
    colors: ['#dc3545', '#f2c010', '#fdff00', '#3f8755'],

  });
  const [seriesLine, setSeriesLine] = useState([]);
  const [totalTest, setToatlTest] = useState();
  /** Map Table */
  const [mapTable, setMapTable] = useState([]);

  /** Top performer */

  const [topPer, setTopPer] = useState([]);

  /** Test Performed  */
  const [testOption, setTestOption] = useState({});
  const [testSeries, setTestSeries] = useState([]);

  /** Bar chart */


  /*******************get apex donut data*********************************************** */

  /*
  const getTotalNoOfBeneficiary = async (data) => { //console.log(data);
  var url='';
  if(reactLocalStorage.get("userLoginType")=='Admin'){
    url='admin/getTotalNoOfBeneficiary';
  }else{
        //if(reactLocalStorage.get("userLoginType")=='Chc'){
   url='chc/getTotalNoOfBeneficiary';
  
  }
  //console.log(data);
    const res = await axios.post(API_URL+url,data).then(ress =>{
      // console.log(Object.values(ress.data.Rdata)
          setOption({labels:Object.keys(ress.data.Rdata),
                      // dataLabels: {enabled: true,style: {colors: ['#000']}},
                      dataLabels: {enabled: true},
                      legend: {show: true,position: 'bottom'},
                      fill: { colors: ['#ff8300', '#1632e4', '#FF0000' ,'#008000','#008080','#800080','#4bcc9d','#f1f107'] },
                      colors: ['#ff8300', '#1632e4', '#FF0000','#008000','#008080','#800080','#4bcc9d','#f1f107'],
                   //   plotOptions:{pie:{dataLabels: {offset: 0,minAngleToShowLabel: 0},donut: { size: '45%'}}}
                      plotOptions:{pie:{donut: { size: '45%'}}}
                      });
          setSeries(Object.values(ress.data.Rdata));
                });
               
  }
                
  */

  const getTotalNoOfBeneficiary = async (data) => { //console.log(data);
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotalBtype';
    } else {
      //if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotalBtype';

    }
    //console.log(data);
    const res = await axios.post(API_URL + url, data).then(ress => {
      // console.log(ress.data.Rdata);
      setAmdatas((ress.data.Rdata));
    });

  }


  /****************************pie Chart*********************************************** */

  /*
  const getTotalNoOfGender = async (data) => { //console.log(data);
    var url='';
    if(reactLocalStorage.get("userLoginType")=='Admin'){
      url='admin/getTotalNoOfGender';
    }else{
          //if(reactLocalStorage.get("userLoginType")=='Chc'){
     url='chc/getTotalNoOfGender';
    
    }
  
      const res = await axios.post(API_URL+url,data).then(ress =>{
        // console.log(Object.values(ress.data.Rdata)
            setPieOption({labels:Object.keys(ress.data.Rdata),dataLabels: {enabled: true},legend: {show: true, position: 'bottom'}});
            setPieSeries(Object.values(ress.data.Rdata));
                  });
                 
    } */

  const getTotalNoOfGender = async (data) => {
   // console.log(data);
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotGender';
    } else {
      //if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotGender';

    }

    const res = await axios.post(API_URL + url, data).then(ress => {
      //  console.log(ress.data.Rdata);
      setDataPie(ress.data.Rdata);
    });

  }


  /****************************Cast pi chart****************************************************** */

  const getTotCast = async (data) => { //console.log(data);
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotCast';
    } else {
      //if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotCast';

    }

    const res = await axios.post(API_URL + url, data).then(ress => {
      // console.log("hfdfd");
      //   console.log(ress.data.Rdata);
      setAmCast(ress.data.Rdata);
    });

  }


  /****************************Cast pi chart****************************************************** */

  const getTotRefCase = async (data) => { //console.log(data);
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotReferredAndNonTreatCount';
    } else {
      //if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotReferredAndNonTreatCount';

    }

    const res = await axios.post(API_URL + url, data).then(ress => {
      // console.log("hfdfd");
      //  console.log(ress.data.Rdata);
      setAmRef(ress.data.Rdata);
    });

  }


  /****************************Cast pi chart followup****************************************************** */

  const getTotFollowup = async (data) => {
    //console.log("followup", data);
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotfollowupAndInvestigation';
    } else {
      //if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotfollowupAndInvestigation';

    }

    const res = await axios.post(API_URL + url, data).then(ress => {
      // console.log("hfdfd");
      //console.log("cons", ress.data.Rdata);
      setAmFollowup(ress.data.Rdata);
    });

  }




  /*************************get Line chart *********************************************** */
  const getTotalNoOfHbCasesMonthly = async (data) => {
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotalNoOfHbCasesMonthly';
    } else {
      // if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotalNoOfHbCasesMonthly';

    }

    const res = await axios.post(API_URL + url, data).then(ress => {
      // console.log(ress.data.monthData);
      // setOption({labels:Object.keys(ress.data.Rdata)});
      setSeriesLine(ress.data.Ldata);
      setToatlTest(ress.data.total_test);
    });

  }



  /****************************data for map table********************************** */
  let getTotalTestCountByDistrict = async (data) => {
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotalTestCountByDistrict';
    } else {
      // if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotalTestCountByLoggedIn';

    }
    const res = await axios.post(API_URL + url, data).then(ress => {
      //  console.log(ress.data);
      setMapTable(ress.data.Rdata);
    });
  }

  /**********************get District Name*************************** */
  const [distName, setDistName] = useState("");


  let getDistrictName = async () => {
    var url = '';
    var data = {};
    if (reactLocalStorage.get("userLoginType") != 'Admin') {
      data = { id: reactLocalStorage.get("district") };
      url = 'chc/getDistrictName';
      const res = await axios.post(API_URL + url, data).then(ress => {
        // console.log(ress.data.Rdata[0].name);
        setDistName(ress.data.Rdata[0]?.name);
      });

    }

  }


  /*****************Top performer**************************************** */

  let topPerformer = async (data, Btyp, van) => {
    let datas = {};
    // console.log(Btyp);
    //  console.log(reactLocalStorage.get("fromDate")); 
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/topPerformer';
      if (Btyp == 'custom') {
        datas = { "chc_phc": data, "btype": Btyp, "fromDate": reactLocalStorage.get("fromDate"), "toDate": reactLocalStorage.get("toDate"), "vanue": van }
      } else {
        datas = { "chc_phc": data, "btype": Btyp, "vanue": van }
      }

    } else {
      // if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/topPerformer';

      if (Btyp == 'custom') {
        datas = { "chc_phc": data, "btype": Btyp, "fromDate": reactLocalStorage.get("fromDate"), "toDate": reactLocalStorage.get("toDate"), "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
      } else {
        datas = { "chc_phc": data, "btype": Btyp, "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
      }
    }
    //   console.log(datas);
    const res = await axios.post(API_URL + url, datas).then(ress => {
      // console.log(ress.data);
      setTopPer(ress.data.Rdata);
    });
  }


  /****************onChange**************************** */

  let getDataTopPerformer = (data) => {
    setTpf(data);
    topPerformer(data, btypes, vanue);
  }


  /*********************Test Performed************************************** */
  /*
  let getTotalTestCountByBlock =  async(data,Btyp)=>{
    let  datas={};
  
    var url='';
    if(reactLocalStorage.get("userLoginType")=='Admin'){
      url='admin/getTotalTestCountByBlock';
       // console.log(Btyp);
      if(Btyp=='custom'){
         datas={"block_id":data,"btype":Btyp,"fromDate":reactLocalStorage.get("fromDate"),"toDate":reactLocalStorage.get("toDate")}
      }else{
        datas={"block_id":data,"btype":Btyp}
      }
    }else{
         // if(reactLocalStorage.get("userLoginType")=='Chc'){
     url='chc/getTotalTestCountByBlock';
  
     if(Btyp=='custom'){
      datas={"block_id":data,"btype":Btyp,"fromDate":reactLocalStorage.get("fromDate"),"toDate":reactLocalStorage.get("toDate"),"userType":reactLocalStorage.get("userType"),"userLoginType":reactLocalStorage.get("userLoginType")}
     }else{
      datas={"block_id":data,"btype":Btyp,"userType":reactLocalStorage.get("userType"),"userLoginType":reactLocalStorage.get("userLoginType")}
      }
  
    }
    const res = await axios.post(API_URL+url,datas).then(ress =>{
     //  console.log(ress.data);
            
            setTestSeries(ress.data.Rdata);
            setTestOption({chart: {
              type: 'bar', height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
              
              }
            }],
            fill: { colors: ['#dc3545', '#f2c010', '#fdff00' ,'#3f8755'] },
            colors: ['#dc3545', '#f2c010', '#fdff00','#3f8755'],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10
              },
            },
            xaxis: {
                    //passing category
              categories:ress.data.category,
              labels: { //for vertical
                rotate: -90,
                style: {
                  fontSize: '9px'
                 }
              },
            },
            legend: {
              position: 'top',
              //fontSize: "32px"
             // offsetY: 40
            },
            fill: {
              opacity: 1
            }})
           // console.log("ds"+testSeries);
        });
     }
  
  let getDataTestPerformed = (data) =>{
          setTperf(data);
          getTotalTestCountByBlock(data,btypes);
  }
  */

  let getTotalTestCountByBlock = async (data, Btyp, van) => {
    let datas = {};

    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = 'admin/getTotalTestCountDistApex';
      // console.log(Btyp);
      if (Btyp == 'custom') {
        datas = { "selected_id": data, "btype": Btyp, "fromDate": reactLocalStorage.get("fromDate"), "toDate": reactLocalStorage.get("toDate"), "vanue": van }
      } else {
        datas = { "selected_id": data, "btype": Btyp, "vanue": van }
      }
    } else {
      // if(reactLocalStorage.get("userLoginType")=='Chc'){
      url = 'chc/getTotalTestCountDistApex';

      if (Btyp == 'custom') {
        datas = { "selected_id": data, "btype": Btyp, "fromDate": reactLocalStorage.get("fromDate"), "toDate": reactLocalStorage.get("toDate"), "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
      } else {
        datas = { "selected_id": data, "btype": Btyp, "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
      }

    }
    const res = await axios.post(API_URL + url, datas).then(ress => {
      // console.log(ress.data);

      setTestSeries(ress.data.Rdata);
      setTestOption({
        chart: {
          type: 'bar', height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {

          }
        }],
        fill: { colors: ['#dc3545', '#f2c010', '#fdff00', '#3f8755'] },
        colors: ['#dc3545', '#f2c010', '#fdff00', '#3f8755'],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            columnWidth: '10%'
          },
        },
        xaxis: {
          //passing category
          categories: ress.data.category,
          labels: { //for vertical
            rotate: -90,
            style: {
              fontSize: '9px'
            }
          },
        },
        legend: {
          position: 'top',
          //fontSize: "32px"
          // offsetY: 40
        },
        fill: {
          opacity: 1
        }

        //   responsive: [{
        //     breakpoint:900 ,

        // }]

      })

      // console.log("ds"+testSeries);
    });
  }

  let getDataTestPerformed = (data) => {
    setTperf(data);
    getTotalTestCountByBlock(data, btypes, vanue);
  }



  /*******************get chc phc ********************************************* */

  let getChcPhc = async () => {
    let datas = {};
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = "admin/getChcPhc";
    } else {

      url = "chc/getChcPhc";
      datas = { "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
    }

    const res = await axios.post(API_URL + url, datas).then(ress => {
      //   console.log(ress.data);
      setChcPhc(ress.data.Rdata);
    });
  }


  /*******************get block datac********************************************* */

  let getDataBlock = async () => {
    let datas = {};
    var url = '';
    if (reactLocalStorage.get("userLoginType") == 'Admin') {
      url = "admin/getDataBlock";
    } else {

      url = "chc/getDataBlock";
      datas = { "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") }
    }
    const res = await axios.post(API_URL + url, datas).then(ress => {
      //console.log('Block');
      //console.log(ress.data);
      setBlock(ress.data.Rdata);
    });
  }



  /************Get all patient test list details count********************** */
  let getAllPatientData = async (e, van) => {
    // console.log(e);
    setPdata(e);
    if (e != 'custom_date') {
      setBtypes(e);

      let type = e;
      let from = "";
      let to = "";

      if (type == "custom") {
        from = moment(fromDate).format('yyyy-MM-DD');
        to = moment(toDate).format('yyyy-MM-DD');

        if (from == undefined || to == undefined) {
          //console.log("in"+from);
          swal("Please fill From and To date");
          return false;
        } else {
          if (from > to) {
            swal("From date must be smaller than To Date");
            return false;
          }
        }
      }


      let datas = { "types": type, 'fromDate': from, 'toDate': to, 'vanue': van }
     // console.log("dataatatata---->", datas);
      //added 16-03
      if (type == "custom") {
        reactLocalStorage.set('fromDate', from);
        reactLocalStorage.set('toDate', to);
      }
      var url = "";
      if (reactLocalStorage.get("userLoginType") == 'Admin') {
        url = 'admin/dashboardDatas';
      } else {
        // if(reactLocalStorage.get("userLoginType")=='Chc'){
        url = 'chc/dashboardDatas';
        datas = { ...datas, ...{ "userType": reactLocalStorage.get("userType"), "userLoginType": reactLocalStorage.get("userLoginType") } }

      }

      //  console.log(datas);
      /**********chart data****************** */
      getTotalNoOfBeneficiary(datas);
      getTotalNoOfGender(datas);
      getTotCast(datas);
      getTotalNoOfHbCasesMonthly(datas);
      getTotalTestCountByDistrict(datas);

      //30-3
      getTotRefCase(datas);
      //added 16-03
      topPerformer(tpf, e, van);
      getTotalTestCountByBlock(tperf, e, van);

      getTotFollowup(datas)

      // getTotalNoOfRefferedCases(datas);
      // getTotalNoOfTestDaily(datas);
      /******************************* */

      /**************box data********************* */
      const res = await axios.post(API_URL + url, datas)
        .then(ress => {
          // console.log("check"+ress.data.all_nonanemic);
          setUserData(ress.data);
          setIsLoading(false);
          //   setUserDataTT(ress.data.datas);
        });
      // console.log(res.data);
    }
  }

  /********************district*********************************** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = 1;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }

  /********************Get all beneficiary type********************************* */
  const getAllBeneficiaryType = async () => {
    const res = await axios.post(API_URL + 'admin/masterBeneficiaryType').then(ress => {
      setUserBtype(ress.data.Rdata);
    });

  }
  //console.log(userBtype);

  /******************all patient data ********************* */
  let getDataForDashboard = (e) => {
    if (e == "custom_date") {
      setHideDiv(true);
    }
    else {
      setHideDiv(false);
    }

    return getAllPatientData(e, '');
  }

  /************************************************ */
  const handleVanue = (e) => {
    setVanue(e.target.value);
    getAllPatientData('all', e.target.value);
  }


  //console.log(new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }));
  return (
    <>


      <div className="container-scroller">

        <Hor_header />

        <div className="container-fluid page-body-wrapper">


          <Ver_Header />

          <div className="main-panel">
            {/* <div className="content-wrapper"> <h3>Coming Soon...</h3></div> */}
            {isLoading ? (<Loader />) : (
              <div className="content-wrapper">

                <div className="row">
                  <div className="col-sm-6">
                    <h3 className="mb-0 font-weight-bold"></h3>
                    {/* <p>Your last login: 21h ago from newzealand.</p> */}
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center justify-content-md-end">

                      {/* <div className="mb-3 mb-xl-0 pr-1">
                        <div className="dropdown">
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <select className="form-control" onChange={(e)=>{setBtype(e.target.value);}}>
                                <option value="all">All Over</option>
                                {userBtype?.map((item,i)=>(
                                  <option  value={item.eng_name}>{item.eng_name}</option>
                                  // <option value={item.id}>{item.eng_name}</option>
                                ))}
                               
                              </select>
                            </div>
                          </div>
                       </div>
                    </div> */}
                      {(reactLocalStorage.get("userLoginType") == 'Admin') ? <div className="mb-3 mb-xl-0 pr-3">
                        <div className="dropdown">
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <select className="form-control" value={vanue} onChange={handleVanue}>
                                <option value="">Venue of the test</option>
                                <option value="VHSND">VHSND</option>
                                <option value="T3 camp">T4 Camp</option>
                                <option value="RBSK Camp">RBSK Camp</option>
                                <option value="School">School</option>
                                <option value="Health Facility">Health Facility</option>
                                <option value="PMSMA">PMSMA</option>
                                <option value="AMB Jharkhand Week">AMB Jharkhand Week</option>
                                <option value="Other">OTHERS</option>




                              </select>
                            </div>
                          </div>
                        </div>
                      </div> : ""}



                      <div className="mb-3 mb-xl-0 pr-1">
                        <div className="dropdown">
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <select className="form-control" onChange={(e) => { getDataForDashboard(e.target.value) }}>
                                <option value="all">All Over</option>
                                <option value="custom_date">Custom Date</option>
                                {userBtype.map((item, i) => (
                                  <option value={item.eng_name}>{item.eng_name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div id="range" className="float-right range" style={{ "display": (hideDiv) ? "" : "none" }}   >
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => { setFromDate(date); setToDate() }}
                        name="fromDate"
                        dateFormat="dd-MM-yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        minDate={new Date('01-01-2021')}
                        maxDate={new Date()}
                        placeholderText="From"
                        className=""
                        id="dobpicker"
                        tabIndex="5"
                        onKeyDown={e => e.preventDefault()}
                        autoComplete='off'
                        required
                      />

                      <DatePicker
                        selected={toDate}
                        onChange={(date) => { setToDate(date); }}
                        name="toDate"
                        dateFormat="dd-MM-yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        minDate={new Date('01-01-2021')}
                        maxDate={new Date()}
                        placeholderText="To"
                        className=""
                        id="dobpicker"
                        tabIndex="5"
                        onKeyDown={e => e.preventDefault()}
                        autoComplete='off'
                        required
                      />
                      <button type="button" onClick={() => { getAllPatientData('custom', ''); }}>Search</button>
                    </div>
                  </div>



                </div>

                <div className="row mt-3">


                  <div className="col-xl-12 d-flex grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        {/* <div className="d-flex flex-wrap justify-content-between">
                      <h4 className="card-title mb-3">Overview</h4>
                    </div> */}
                        {/* <div className="row"> */}
                        {/* <div className="col-12"> */}

                        <div className="d-flex justify-content-between mb-4">
                          {/* AM Chart donut */}
                          {(amdatas.length > 0) ? <div className="col-sm-5"><div><h4 className="card-title text-center mb-3">Type Wise Beneficiary</h4></div><AmDonuts options={amdatas} /></div> : ""}

                          {/* AM Chart Pie */}
                          {(dataPie.length > 0) ? <div className="col-sm-3"><div><h4 className="card-title text-center mb-3">Gender Wise Beneficiary</h4></div><AmPie options={dataPie} /></div> : ""}

                          {/* AM Chart Pie cast */}
                          {(amCast.length > 0) ? <div className="col-sm-4"><div><h4 className="card-title text-center mb-3">Caste Wise Beneficiary</h4></div><AmPies options={amCast} /></div> : ""}
                          {/* <div><PieApex options={pieOptions} series={pieSeries}  /></div> */}

                          {/* <div><ApexData options={options} series={series}  /></div> */}

                        </div>

                        {/* </div> */}
                        {/* </div> */}
                      </div>

                    </div>
                  </div>




                </div>

                <div className="row mt-3">

                  <div className="col-xl-8 d-flex grid-margin stretch-card">
                    <div className="card">
                      <h4 className="card-title mb-3" style={{ "marginLeft": "13px", "marginTop": "12px" }}>Trending</h4>
                      <div className="card-body">
                        <LineApexes id="line" options={optionsLine} series={seriesLine} totalTest={totalTest} />

                      </div>
                    </div>
                  </div>


                  <div className="col-xl-4 d-flex grid-margin stretch-card">
                    <div className="card">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tabbable" id="tabs-194795">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className="nav-link active show" href="#tab1" data-toggle="tab">Map</a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link  " href="#tab2" data-toggle="tab">Table</a>
                              </li>
                            </ul>
                            <div className="tab-content" style={{ "border": "0px" }}>
                              <div className="tab-pane active" id="tab1">
                                <h4 className="card-title mb-3" > JHARKHAND  {distName != "" ? "(" + distName + ")" : ""}</h4>
                                <MapCharts setTooltipContent={setContent} dataMap={mapTable} disName={distName} />
                                <ReactTooltip>{content}</ReactTooltip>
                                {/* <div className='col-md-12'><div className="box-sz" style={{"backgroundColor":"#1C9A57"}}></div>0</div>
                        <div className='col-md-12'><div className="box-sz" style={{"backgroundColor":"#da746b"}}></div>1-300</div>
                        <div className='col-md-12'><div className="box-sz" style={{"backgroundColor":"#d45d52"}}></div>300-700</div>
                        <div className='col-md-12'><div className="box-sz" style={{"backgroundColor":"#ce4639"}}></div>700-1500</div>
                        <div className='col-md-12'><div className="box-sz" style={{"backgroundColor":"#c21808"}}></div>More than 1500</div>
                        */}
                              </div>
                              <div className="tab-pane " id="tab2">
                                <div className="table-responsive pt-3">
                                  <table className="table table-bordered display  nowrap" id="example">
                                    <thead>
                                      <tr>
                                        <th>{(reactLocalStorage.get('userLoginType') == "Admin") ? "District Name" : ((reactLocalStorage.get('userLoginType') == "Chc" || reactLocalStorage.get('userLoginType') == "Block") ? "Chc Name" : ((reactLocalStorage.get('userLoginType') == "District") ? "Block Name" : "Name"))} </th>
                                        <th> Severe </th>
                                        <th> Moderate </th>
                                        <th> Mild </th>
                                        <th> Non-anemic </th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      {mapTable?.map((udata, index) => (
                                        <tr key={index}>
                                          <td>  {udata.dbc_name}</td>
                                          <td> {udata.severe}</td>
                                          <td> {udata.moderate}</td>
                                          <td> {udata.mild} </td>
                                          <td> {udata.nonanemic}</td>

                                        </tr>
                                      )
                                      )}

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">

                  <div className="col-xl-4 d-flex grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">

                          {(amRef.length > 0) ? <div className="col-sm-12"><div><h4 className="card-title mb-3">Referred Cases </h4></div><AmPieRef options={amRef} /></div> : ""}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 d-flex grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex flex-wrap justify-content-between">
                          <h4 className="card-title mb-3">Total Beneficiary</h4>
                          <h4 className="card-title mb-3"><Link to={'/all_data_member_list/all/' + pdata}>{userData.No_of_tot_test}</Link></h4>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-sm-12">

                                <div className="d-flex justify-content-between mt-4">
                                  <div>Severe</div>
                                  <div className="text-muted"><Link to={'/all_data_member_list/severe/' + pdata}>{userData.all_severe}</Link></div>
                                </div>
                                <div className="progress progress-md mt-2">
                                  <div className="progress-bar bg-danger" role="progressbar" style={{ "width": ((userData.all_severe * 100) / userData.No_of_tot_test) + "%" }} aria-valuenow={userData.all_severe} aria-valuemin="0" aria-valuemax={userData.No_of_tot_test}></div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                  <div>Moderate</div>
                                  <div className="text-muted"><Link to={'/all_data_member_list/moderate/' + pdata}>{userData.all_moderate}</Link></div>
                                </div>
                                <div className="progress progress-md mt-2">
                                  <div className="progress-bar bg-warning" role="progressbar" style={{ "width": ((userData.all_moderate * 100) / userData.No_of_tot_test) + "%" }} aria-valuenow={userData.all_moderate} aria-valuemin="0" aria-valuemax={userData.No_of_tot_test}></div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                  <div>Mild</div>
                                  <div className="text-muted"><Link to={'/all_data_member_list/mild/' + pdata}>{userData.all_mild}</Link></div>
                                </div>
                                <div className="progress progress-md mt-2">
                                  <div className="progress-bar " role="progressbar" style={{ "backgroundColor": "yellow", "width": ((userData.all_mild * 100) / userData.No_of_tot_test) + "%" }} aria-valuenow={userData.all_mild} aria-valuemin="0" aria-valuemax={userData.No_of_tot_test}></div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                  <div>Non-anemic</div>
                                  <div className="text-muted"><Link to={'/all_data_member_list/nonanemic/' + pdata}>{userData.all_nonanemic}</Link></div>
                                </div>
                                <div className="progress progress-md mt-2">
                                  <div className="progress-bar bg-success" role="progressbar" style={{ "width": ((userData.all_nonanemic * 100) / userData.No_of_tot_test) + "%" }} aria-valuenow={userData.all_nonanemic} aria-valuemin="0" aria-valuemax={userData.No_of_tot_test}></div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-xl-4 d-flex grid-margin stretch-card">
                    <div className="card">

                      <div className="card-body">
                        <h4 className="card-title mb-3" >Top Performer</h4>
                        {/* <div className="col-md-8 sete"> 
                        <select className="form-control" onChange={(e)=>{getDataTopPerformer(e.target.value)}}>
                                <option value="all">All Over</option>
                                   {chcPhc.map((item,i)=>(
                                <option value={item.id}>{item.name}</option>
                                ))}
                         </select>
                       </div>        */}
                        <div className="col-md-8 sete">
                          <select className="form-control" onChange={(e) => { getDataTopPerformer(e.target.value) }}>
                            <option value="all">All Over</option>
                            {(reactLocalStorage.get("userLoginType") == 'District') ? block.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : (reactLocalStorage.get("userLoginType") == 'Admin') ? districtData.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : (reactLocalStorage.get("userLoginType") == 'Block') ? chcPhc.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : ""}
                          </select>
                        </div>

                        {topPer.length ? topPer.map((udata, index) => (

                          <div className="d-flex justify-content-between mt-4 lines">
                            <div className='rts'><span className='prt'>{index + 1} </span> <span className="card-title" style={{ "paddingLeft": "5px" }}>{udata.anm_name}</span><br />
                              <span style={{ "marginLeft": "27px" }}>{udata.chc_name}</span><br />
                              <span style={{ "marginLeft": "27px" }}>{udata.district_name}</span>
                            </div>
                            <div className="card-title">{udata.cnt}</div>

                          </div>

                        )
                        ) : <div className="d-flex justify-content-between mt-4 lines"><p>No data Found</p></div>}


                      </div>
                    </div>
                  </div>

                </div>

                <div className="row mt-3">
                  <div className="col-xl-12 d-flex grid-margin stretch-card">
                    <div className="card">

                      <div className="card-body">
                        <h4 className="card-title mb-3" >Test Performed</h4>
                        <div className="col-md-4 seta">
                          <select className="form-control" onChange={(e) => { getDataTestPerformed(e.target.value) }}>
                            <option value="all">All Over</option>
                            {(reactLocalStorage.get("userLoginType") == 'District') ? block.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : (reactLocalStorage.get("userLoginType") == 'Admin') ? districtData.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : (reactLocalStorage.get("userLoginType") == 'Block') ? chcPhc.map((item, i) => (
                              <option value={item.id}>{item.name}</option>
                            )) : ""}
                          </select>
                        </div>


                        <StackedBarApexes id="barss" options={testOption} series={testSeries} />

                      </div>
                    </div>
                  </div>


                </div>

                <div className="row mt-3">
                  <div className="col-xl-4 d-flex grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">

                          {(amFollowup.length > 0) ? <div className="col-sm-12"><div><h4 className="card-title mb-3">Current month Follow-up Status </h4></div><AmPiesFollowup options={amFollowup} /></div> : ""}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row mt-3">
              <div className="col-xl-12 d-flex grid-margin stretch-card">
                  <div className="card">
                     
                    <div className="card-body">
                    <h4 className="card-title mb-3" >Test Performed</h4>
                    <div className="col-md-4 seta"> 
                        <select className="form-control" onChange={(e)=>{getDataTestPerformed(e.target.value)}}>
                                <option value="all">All Over</option>
                                   {block.map((item,i)=>(
                                <option value={item.id}>{item.name}</option>
                                ))}
                         </select>
                       </div>  

             
                    <StackedBarApexes  id="barss" options={testOption} series={testSeries} />
                     
                  </div>
                </div>
              </div>
                
               
              </div>  */}


                {/* <div className="row mt-3">
              <div className="col-xl-6 d-flex grid-margin stretch-card">
                  <div className="card">
                  <span style={{"marginLeft":"10px","marginTop":"5px"}}>Bar chart state(Referred  Cases)</span>
                    <div className="card-body">
                    <BarApexes  id="bar" options={optionsBar} series={seriesBar} />
                     
                  </div>
                </div>
              </div>
                
              <div className="col-xl-6 d-flex grid-margin stretch-card">
                  <div className="card">
                  <span style={{"marginLeft":"10px","marginTop":"5px"}}>Bar chart state(Total no. of tests/day)</span>
                    <div className="card-body">
                      <BarApexes  id="bars" options={optionsBarTest} series={seriesBarTest} />
                     
                  </div>
                </div>
              </div> 
              </div>   */}




              </div>
            )}

            <Footer />

          </div>

        </div>

      </div>
    </>
  );
}

export default Dashboard;                                      
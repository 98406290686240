import React, { useEffect, useState } from "react";

import Hor_header from "../header/hor_header";
import Ver_header from "../header/ver_header";
import Footer from "../footer/footer";
import { API_URL } from "../constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { reactLocalStorage } from 'reactjs-localstorage';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

function AddUser() {

  /********************************************* */
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [chcData, setChcData] = useState([]);
  const [villageData, setVillageData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  const [subcenterData, setSubcenterData] = useState([]);
  /******************pass & Cpass************************** */
  const [passData, setPassData] = useState();
  const [cpassData, setCpassData] = useState();

  /********************medicalteam Hide Show************************* */

  const [hideDiv, setHideDiv] = useState(false);

  const [lTCHO, setLTCHO] = useState(0);

  const [hideQuest, setHideQuest] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navegate = useNavigate();
  let clickCancel = () => {
    return navegate('/');
  }

  const [hideDivAnm, setHideDivAnm] = useState(false);

  /*****************Date****************************** */
  const [startDate, setStartDate] = useState();
  /*************userd as document.ready -  useEffect****************** */
  useEffect(() => {
    document.title = 'Add User';
    //   getStateData();
  });

  let handleUserType = (e) => {
    //getting state name   
    getStateData();
    //on select of medicalteam show two more feilds
    if (e.target.value === 'medicalteam') {
      setHideDiv(true);
    } else {
      setHideDiv(false);
      //resetting values
      setSchoolData([]);
      setVillageData([]);
    }
    // if (e.target.value === 'anm') {
    //   setHideDivAnm(true);
    // } else {
    //   setHideDivAnm(false);
    //   //resetting values
    //   setSubcenterData([]);
    // }
    /*  if(e.target.value=='LT' || e.target.value=='CHO'){
         setLTCHO(1); 
      }else{
        setLTCHO(0); 
      } */
  }

  let hideShow = (e) => {
    if (e === 'yes') {
      setHideQuest(true);
    } else {
      setHideQuest(false);
    }
  }


  /********State*********** */
  let getStateData = async () => {
    const regDataState = {
      method: 'Get',
      headers: { 'Content-Type': 'application/json' }
    }
    const getState = await fetch(API_URL + 'admin/state', regDataState);
    const getdatastate = await getState.json();
    setStateData(getdatastate);
  }

  /********District based on state*********** */
  let getDistrictList = async (e) => {
    setDistrictData([]);
    let state_id = e.target.value;
    const regDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'state_id': state_id })
    }
    const getDistrict = await fetch(API_URL + 'admin/district', regDataDistrict);
    const getdataDistrict = await getDistrict.json();
    // console.log(getdataDistrict.Rdata);
    if (getdataDistrict.Rdata.length > 0) {
      setDistrictData(getdataDistrict.Rdata);
    }
  }
  /********Block based on district*********** */
  let getBlock = async (e) => {
    setBlockData([]);
    let district_id = e.target.value;
    //console.log(district_id);
    const reDataDistrict = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'district_id': district_id })
    }
    const getBlock = await fetch(API_URL + "admin/block", reDataDistrict);
    const getBlockData = await getBlock.json();
    //console.log(getBlockData);
    if (getBlockData.Rdata.length > 0) {
      setBlockData(getBlockData.Rdata);
    }
    // else{
    //   const data=[{'id':'','name':''}];
    //   setBlockData(data);
    // }
  }



  /****************Get Chc ,school,village based on block**************************** */

  let getChc = async (e) => {
    setChcData([]);
    setVillageData([]);
    setSchoolData([]);
    let block_id = e.target.value;
    //console.log(district_id);
    const reDataBlock = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'block_id': block_id })
    }
    /************chc************** */
    const getChc = await fetch(API_URL + "admin/getChc", reDataBlock);
    const getChcData = await getChc.json();
    console.log(getChcData);
    if (getChcData.Rdata.length > 0) {
      setChcData(getChcData.Rdata);
    }
    /***********village************** */
    /* const getVillage = await fetch(API_URL+"admin/getVillage",reDataBlock);
     const getVillageData = await getVillage.json();
     //console.log(getBlockData);
     if(getVillageData.Rdata.length > 0){
     setVillageData(getVillageData.Rdata); 
     }  */

    /***********School************** */
    /* const getSchool = await fetch(API_URL+"admin/getSchool",reDataBlock);
     const getSchoolData = await getSchool.json();
     //console.log(getBlockData);
     if(getSchoolData.Rdata.length > 0){
     setSchoolData(getSchoolData.Rdata);
     }  */
  }

  /*********************get sub center based on chc************************************** */
  let getSubcenter = async (e) => {
    setSubcenterData([]);
    let chc_phc_id = e.target.value;
    //console.log(district_id);
    const reDatachc = {
      method: 'Post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'chc_phc_id': chc_phc_id })
    }
    const getSubCet = await fetch(API_URL + "admin/sub_center", reDatachc);
    const getSbCData = await getSubCet.json();
    //console.log(getBlockData);
    if (getSbCData.Rdata.length > 0) {
      setSubcenterData(getSbCData.Rdata);
    }

  }



  /****************set pass and cpass*********************/
  let getPassword = (e) => {
    setPassData(e.target.value);

  }
  let getConfirm = (e) => {
    setCpassData(e.target.value);

    // if(passData!=cpassData){
    //   swal("Password  and Confirm Pasword not matched");

    //  }
  }

  /*****************submit******************************* */
  const submitUser = async (events) => {
    events.preventDefault();
    if (validate(events)) {
       setIsSubmitting(true);
      const data = new FormData(events.target);
      //  var schoolDa='';  var villageDa='';
      var schoolDa = 0; var villageDa = 0;
      var subCenterDa = 0;
      /* if(data.get('school')==='Select'){
          schoolDa = 0;
       }else{
          schoolDa=data.get('school');
       }
       if(data.get('village')==='Select'){
          villageDa = 0;
       }else{
          villageDa=data.get('school');
       } */
      if (data.get('sub_center') === 'Select') {
        subCenterDa = 0;
      } else {
        subCenterDa = data.get('sub_center');
      }


      let formData = {
        'user_type': data.get('user_type'),
        'name': data.get('name'),
        'gender': data.get('gender'),
        'dob': data.get('dob'),
        'mobile_no': data.get('mobile_no'),
        'state_id': data.get('state'),
        'district_id': data.get('district'),
        'block_id': data.get('block'),
        'chc_phc_id': data.get('chc'),
        'village_id': villageDa,
        'school_id': schoolDa,
        'sub_center_id': subCenterDa,
        'password': data.get('password'),
        'req_user_type': "admin",
        'dh_available': data.get('dh_available'),
        'dh_functional': data.get('dh_functional'),
        'dh_training': data.get('dh_training'),
        'consumable': data.get('consumable')

      };
      //'req_user_type' : "admin" // for approving user by default approved
      //  console.log(formData); 

     /* const regDatas = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }

      const getResps = await fetch(API_URL + 'admin/register', regDatas);
      const getDatas = await getResps.json();
     swal(getDatas.msg);
     */
       try {
              const getResps = await fetch(API_URL + 'admin/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
              });

              const getDatas = await getResps.json();
               if (getResps.ok) {
                  swal(getDatas.msg);
                } else {
                  swal("Something went wrong, please try again.");
                }
            } catch (error) {
              swal("Something went wrong, please try again.");
             // console.log("Error:", error);
              // setIsSubmitting(false);
            } finally {
              setIsSubmitting(false); // Enable button after API call
            }
    }
  }


  /***************validation**************************** */
  let validate = (ev) => {
    const data = new FormData(ev.target);
    // console.log(data.get);
    let validat = true;

    // if(data.get('email') == ''){
    //    swal('Please enter email');
    //    validat = false;
    //  }else{
    //   let emailRegExp="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    //    //let testemail= emailRegExp.test(data.get('email'));
    //    let testemail= data.get('email').match(emailRegExp);
    //    if(testemail){
    //      validat = true;
    //    }else{ swal('Invalid email');
    //     validat = false;
    //    }
    //  }


    if (data.get('name') == '') {
      swal('Please enter name');
      return validat = false;
    } else {
      let nameRegExp = "^[a-zA-Z]";
      //let testemail= emailRegExp.test(data.get('email'));
      let testname = data.get('name').match(nameRegExp);
      if (testname) {
        validat = true;
      } else {
        swal('invalid name');
        return validat = false;
      }
    }

    if (data.get('mobile_no') == '') {
      swal('Please enter mobile');
      return validat = false;
    } else {
      let mobRegExp = "^[0-9]{10}";
      //let testemail= emailRegExp.test(data.get('email'));
      let mobname = data.get('mobile_no').match(mobRegExp);
      if (mobname) {
        validat = true;
      } else {
        swal('Invalid mobile');
        return validat = false;
      }
    }

    if (data.get('password') == '') {
      swal('Please enter password');
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('cpassword') == '') {
      swal('Please enter confirm password');
      return validat = false;
    } else {
      validat = true;
    }

    if (passData != cpassData) {
      swal("Password  and confirm pasword not matched");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('state') == "Select") {
      swal("Please select state");
      return validat = false;
    } else {
      validat = true;
    }
    if (data.get('district') == "Select") {
      swal("Please select district");
      return validat = false;
    } else {
      validat = true;
    }
    if (lTCHO != 1) {
      if (data.get('block') == "Select") {
        swal("Please select block");
        return validat = false;
      } else {
        validat = true;
      }
    }
    if (data.get('user_type') == "Select") {
      swal("Please select user type");
      return validat = false;
    } else {
      validat = true;
    }
    if (lTCHO != 1) {
      if (data.get('chc') == "Select") {
        swal('Please select Chc');
        return validat = false;
      } else {
        validat = true;
      }
    }
    if (data.get('user_type') == "anm") {
      if (data.get('sub_center') == 'Select') {
        // disable on 14-09-2022
        //  swal('Please select Sub Center');
        //  return validat = false;  
        return validat = true;
      }
    } else {
      validat = true;
    }



    return validat;
  }


  // console.log(blockData);


  /******************************* */
  return (
    <div className="container-scroller">

      <Hor_header />

      <div className="container-fluid page-body-wrapper">


        <Ver_header />


        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">

              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">New ANM/Medical Team Registration</h4>
                    <form className="form-sample" onSubmit={submitUser}>
                      {/* <p className="card-description">
                      User Type
                    </p> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12"> User Type *</label>
                            <div className="col-sm-12">
                              <select className="form-control" onChange={handleUserType} name="user_type">
                                <option value="">Select</option>
                                <option value="anm">ANM</option>
                                <option value="medicalteam">RBSK Medical Team</option>
                                <option value="nurse">Staff Nurse</option>
                                <option value="LT">LT</option>
                                <option value="CHO">CHO</option>
                                <option value="others">Others</option>
                                {/* <option value="asha">Asha</option> */}
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">RCH Id *</label>
                          <div className="col-sm-12">
                            <input name="rch_id" type="text"  className="form-control" required />
                          </div>
                        </div>
                      </div> */}

                      </div>

                      <p className="card-description">
                        Personal info
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Name *</label>
                            <div className="col-sm-12">
                              <input name='name' type="text" className="form-control" autoComplete="off" required />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Mobile Number *</label>
                            <div className="col-sm-12">
                              <input name="mobile_no" type="tel" className="form-control" autoComplete="off" pattern="[6789][0-9]{9}" maxLength={10} required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12"> Gender *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="gender" required>
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Date of Birth *</label>
                            <div className="col-sm-12">
                              {/* <input name="dob" type="date"className="form-control" placeholder="dd/mm/yyyy" required/> */}
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                name="dob"
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                minDate={new Date('01-01-1950')}
                                maxDate={new Date()}
                                placeholderText="Date of Birth"
                                className="form-control"
                                id="dobpicker"
                                tabIndex="5"
                                onKeyDown={e => e.preventDefault()}
                                autoComplete="off"

                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="card-description">
                        Geographical Data
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select State *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="state" onChange={getDistrictList} required>
                                <option>Select State</option>
                                {stateData.map(states => (
                                  <option value={states.id}>{states.state}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select District *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="district" onChange={getBlock} required>
                                <option>Select</option>
                                {districtData.map(district => (
                                  ((reactLocalStorage.get("userLoginType") == "Chc") || (reactLocalStorage.get("userLoginType") == "Block") || (reactLocalStorage.get("userLoginType") == "District")) ? ((reactLocalStorage.get("district") == district.id) ? <option value={district.id}>{district.name} </option> : "") : <option value={district.id}>{district.name} </option>

                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Block *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="block" onChange={getChc} required>
                                <option>Select</option>
                                {blockData.map(blocks => (
                                  ((reactLocalStorage.get("userLoginType") == 'Chc') || (reactLocalStorage.get("userLoginType") == 'Block')) ? (reactLocalStorage.get("block") == blocks.id) ? <option value={blocks.id}>{blocks.name}</option> : "" : <option value={blocks.id}>{blocks.name}</option>

                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select CHC/PHC *</label>
                            <div className="col-sm-12">
                              <select className="form-control" name="chc" onChange={getSubcenter} required>
                                <option>Select</option>
                                {chcData.map(chc => (
                                  (reactLocalStorage.get("userLoginType") == 'Chc') ? ((reactLocalStorage.get("userType") == chc.id) ? <option value={chc.id}>{chc.name}</option> : "") : <option value={chc.id}>{chc.name}</option>

                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <div className="row" style={{"display":(hideDiv)?"":"none"}}>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select School *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="school" required>
                            <option>Select</option>
                            {schoolData.map( sch => (
                               <option value={sch.id}>{sch.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-12">Select Village *</label>
                          <div className="col-sm-12">
                            <select className="form-control" name="village" required>
                            <option>Select</option>
                            {villageData.map( vil => (
                               <option value={vil.id}>{vil.name}</option>
                             ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      
                    </div> */}

                     {/* // <div className="row" style={{ "display": (hideDivAnm) ? "" : "none" }}> */}
                      <div className="row" >
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Select Sub Center </label>
                            <div className="col-sm-12">
                              <select className="form-control" name="sub_center" required>
                                <option>Select</option>
                                {subcenterData.map(sb => (
                                  <option value={sb.id}>{sb.sub_center}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="card-description">
                        Hemoglobinometer
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Digital hemoglobinometer is available *</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_available" id="membershipRadios1" onClick={(e) => { hideShow(e.target.value) }} value="yes" />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_available" id="membershipRadios2" onClick={(e) => { hideShow(e.target.value) }} value="no" defaultChecked />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ "display": (hideQuest) ? "" : "none" }}>
                          <div className="form-group row">
                            <label className="col-sm-12">Digital hemoglobinometer is functional*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_functional" id="membershipRadios3" value="yes" />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_functional" id="membershipRadios4" value="no" defaultChecked />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" >
                        <div className="col-md-6" style={{ "display": (hideQuest) ? "" : "none" }}>
                          <div className="form-group row">
                            <label className="col-sm-12">Have you received training to use the hemoglobinometer*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_training" id="membershipRadios5" value="yes" />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="dh_training" id="membershipRadios6" value="no" defaultChecked />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Do you have Consumable item*</label>
                            <div className="col-sm-12">

                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="consumable" id="membershipRadios7" value="yes" />
                                  Yes
                                  <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="consumable" id="membershipRadios8" value="no" defaultChecked />
                                  No
                                  <i className="input-helper"></i></label>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>



                      <p className="card-description">

                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Password *</label>
                            <div className="col-sm-12">
                              <input type="password" className="form-control" id="exampleInputPassword1" onChange={getPassword} minlength="4" maxlength="4" name="password" placeholder="Password" autoComplete="off" required />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-12">Re-enter Password *</label>
                            <div className="col-sm-12">
                              <input type="password" className="form-control" id="exampleInputConfirmPassword1" onChange={getConfirm} minlength="4" maxlength="4" name="cpassword" placeholder="Re-enter Password" autoComplete="off" required />
                            </div>
                          </div>
                        </div>
                      </div>



                      <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting} >{isSubmitting ? "Submitting..." : "Submit"}</button>
                      <button type="button" className="btn btn-light" onClick={clickCancel}>Cancel</button>
                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Footer />

        </div>

      </div>


    </div>


  );
}

export default AddUser;